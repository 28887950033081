import { UserApi } from '@/api-client/user';
import { CustomerApi } from '@/api-client/user/customer';
import { IProfile } from '@/interface/auth';
import { EnumGender, UserRoleKey } from '@/utils/enum';
import { handleCompressImage } from '@/utils/global-func';
import { FormOutlined } from '@ant-design/icons';
import { Avatar, Input, Modal, notification, Radio, Tabs } from 'antd';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Membership from '../../../../public/svgs/Membership.svg';
import { MembershipPersonal } from './membership-personal';
import UpdatePassword from './update-password';

export interface IProfileInfoProps {
  isModalOpen: boolean;
  profile: IProfile;
  setOpenModal: (v: boolean) => void;
  mutateProfile: () => void;
  urlBack?: string;
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav-list {
    width: 100%;
    display: flex !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    transition: all 0.3s ease-in-out;
    &::before {
      border: none !important; /* Remove bottom border */
    }
  }

  .ant-tabs-content-holder {
    padding-top: 24px !important;
  }

  .ant-tabs-nav-list {
    // background-color: #f0f2f5;
    border-radius: 6px;
    padding: 4px;
    gap: 4px;
  }

  .ant-tabs-tab-btn {
    width: 100%;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    padding: 6px 16px;
    border-radius: 6px;
    color: #637381;
    font-weight: 500;
    transition: all 0.3s;
    flex: 1;
    justify-content: center;
    text-align: center;

    &:hover {
      color: #1d3f75;
    }

    &.ant-tabs-tab-active {
      background-color: #1d3f75;

      .ant-tabs-tab-btn {
        color: white !important;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
`;

export default function ProfileInfo(props: IProfileInfoProps) {
  const { isModalOpen, profile, setOpenModal, mutateProfile, urlBack } = props;
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [firstName, setFirstName] = useState<string>(profile?.first_name);
  const [lastName, setLastName] = useState<string>(profile?.last_name);
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState<EnumGender | null>(null);
  const pathName = usePathname();
  const [openUpdatePass, setOpenUpdatePass] = useState(false);
  const [activeKey, setActiveKey] = useState('1');

  const handleClose = () => {
    setOpenModal(false);
    setFirstName('');
    setLastName('');
    setGender(null);
    setImage(null);
  };

  useEffect(() => {
    if (!profile || !isModalOpen) return;
    setFirstName(profile?.first_name);
    setLastName(profile?.last_name);
    setGender((profile.gender as EnumGender) || null);
  }, [profile, isModalOpen]);

  const avatarGenerator = useCallback(() => {
    if (isModalOpen) {
      if (image) {
        return URL.createObjectURL(image);
      } else {
        return profile?.avatar ? `/service/${profile?.avatar}` : '';
      }
    }
  }, [image, profile?.avatar, isModalOpen]);

  const handleUpdateProfile = useCallback(async () => {
    if (loading) {
      return;
    }
    if (!firstName || (typeof firstName === 'string' && firstName.length === 0)) {
      notification.error({
        message: 'Please enter your name',
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('gender', gender || '');
    if (image) {
      const imageResize = await handleCompressImage(image);
      formData.append('file', imageResize, image.name);
    }
    if (profile.role !== UserRoleKey.CUSTOMER) {
      UserApi.updateProfile(formData)
        .then(() => {
          notification.success({
            message: 'Update successfully',
          });
          handleClose();
          mutateProfile();
        })
        .catch(error => {
          if (Array.isArray(error?.response?.data?.message)) {
            error?.response?.data?.message.forEach((item: any) => {
              notification.error({
                message: 'Update failed',
                description: item,
              });
            });
          } else {
            notification.error({
              message: 'Update failed',
              description: error?.response ? error.response.data?.message : error.message,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      CustomerApi.updateProfile(formData)
        .then(() => {
          notification.success({
            message: 'Update successfully',
          });
          handleClose();
          mutateProfile();
        })
        .catch(error => {
          if (Array.isArray(error?.response?.data?.message)) {
            error?.response?.data?.message.forEach((item: any) => {
              notification.error({
                message: 'Update failed',
                description: item,
              });
            });
          } else {
            notification.error({
              message: 'Update failed',
              description: error?.response ? error.response.data?.message : error.message,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [image, loading, firstName, handleClose, lastName, gender]);

  const memberShipActive = profile?.memberships && profile?.memberships?.find(item => item.active === true)?._id;

  return (
    <Modal
      title={
        <div>
          <p className='mb-0 text-xl font-bold text-zelene-blue'>Account information</p>
        </div>
      }
      open={isModalOpen}
      onOk={handleUpdateProfile}
      onCancel={handleClose}
      okText='Save'
      width={560}
      loading={loading}
    >
      <div className='pb-5'>
        <div style={{ gap: '24px', margin: '32px' }}>
          <div className='relative mx-auto flex justify-center'>
            <Avatar src={avatarGenerator()} size={90} style={{ cursor: 'pointer' }}>
              {profile?.first_name?.charAt(0).toUpperCase()}
            </Avatar>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                right: '42%',
                transform: 'translate(58%, 0)',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (fileRef?.current) {
                  fileRef.current.click();
                }
              }}
            >
              <div
                style={{
                  width: '32px',
                  height: '32px',
                  position: 'relative',
                }}
              >
                <Image src='/svgs/camera-rounded-icon.svg' fill alt='photo' />
              </div>
            </div>
            <input
              type='file'
              hidden
              ref={fileRef}
              onChange={e => {
                const { files } = e.target;

                if (files && files[0]) {
                  const file = files[0];

                  if (file?.type?.startsWith('image/')) {
                    setImage(file);
                  }
                }
              }}
              accept='image/*'
            />
          </div>

          <p
            style={{
              color: '#323232',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              textAlign: 'center',
              marginBottom: 0,
              fontFamily: 'Roboto',
              paddingTop: 5,
            }}
            className='flex items-center justify-center gap-2'
          >
            {memberShipActive && (
              <Image src={Membership} alt='icon' width={24} height={24} className='size-6 object-contain' />
            )}

            {profile.first_name + ' ' + profile.last_name}
          </p>

          {memberShipActive && (
            <span className='flex items-center justify-center font-hneu text-base text-zelene-blue'>
              {profile.memberships.find(item => item.active === true)?.category.name}
            </span>
          )}
        </div>

        <StyledTabs
          activeKey={activeKey}
          defaultActiveKey='1'
          items={[
            {
              label: 'My Profile',
              key: '1',
              children: (
                <div className='flex flex-col gap-4'>
                  <div className='flex w-full border-b-2 py-1'>
                    <div className='w-[40%]'>
                      <p className='mb-0 text-sm text-gray-400'>First name</p>
                    </div>
                    <div className='flex w-[60%] justify-between'>
                      <Input
                        placeholder='Please enter fullname...'
                        value={firstName}
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          color: '#000',
                          maxWidth: '80%',
                          padding: '0px 0px',
                        }}
                        variant='borderless'
                        onChange={e => setFirstName(e.target.value)}
                      />
                      <FormOutlined style={{ fontSize: 20 }} />
                    </div>
                  </div>
                  <div className='flex w-full border-b-2 py-1'>
                    <div className='w-[40%]'>
                      <p className='mb-0 text-sm text-gray-400'>Last name</p>
                    </div>
                    <div className='flex w-[60%] justify-between'>
                      {/* <p className='mb-0 text-base font-semibold text-[#000]'>{profile.first_name + ' ' + profile.last_name}</p> */}
                      <Input
                        placeholder='Please enter fullname...'
                        value={lastName}
                        style={{
                          fontSize: 16,
                          fontWeight: 500,
                          color: '#000',
                          maxWidth: '80%',
                          padding: '0px 0px',
                        }}
                        variant='borderless'
                        onChange={e => setLastName(e.target.value)}
                      />
                      <FormOutlined style={{ fontSize: 20 }} />
                    </div>
                  </div>
                  <div className='flex w-full justify-between border-b-2 py-1'>
                    <div className='w-[40%]'>
                      <p className='mb-0 text-sm text-gray-400'>Password</p>
                    </div>
                    <div className='flex w-[60%] justify-between'>
                      <p className='mb-0 text-xl font-semibold text-[#000]'>*********</p>
                      <FormOutlined
                        style={{ fontSize: 20, cursor: 'pointer' }}
                        onClick={() => setOpenUpdatePass(true)}
                      />
                    </div>
                  </div>
                  <div className='flex w-full justify-between border-b-2 py-1'>
                    <div className='w-[40%]'>
                      <p className='mb-0 text-sm text-gray-400'>Gender</p>
                    </div>
                    <div className='flex w-[60%]'>
                      <Radio.Group onChange={e => setGender(e.target.value)} value={gender}>
                        <Radio value={EnumGender.MALE}>Male</Radio>
                        <Radio value={EnumGender.FEMALE}>Female</Radio>
                        <Radio value={EnumGender.OTHER}>Other</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className='flex w-full justify-between border-b-2 py-1'>
                    <div className='w-[40%]'>
                      <p className='mb-0 text-sm text-gray-400'>Phone</p>
                    </div>
                    <div className='flex w-[60%] justify-between'>
                      <p className='mb-0 text-base font-semibold text-[#000]'>{profile.phone}</p>
                      {/* <FormOutlined style={{ fontSize: 20, opacity: 0 }} /> */}
                    </div>
                  </div>
                  <div className='flex w-full justify-between border-b-2 py-1'>
                    <div className='w-[40%]'>
                      <p className='mb-0 text-sm text-gray-400'>Email</p>
                    </div>
                    <div className='flex w-[60%] justify-between'>
                      <p className='mb-0 text-base font-semibold text-[#000]'>{profile.email}</p>
                      {/* <FormOutlined style={{ fontSize: 20, opacity: 0 }} /> */}
                    </div>
                  </div>
                </div>
              ),
            },
            {
              label: 'Membership',
              key: '2',
              children: (
                <MembershipPersonal />
                // <div className='rounded-lg bg-[#DBEAF8] p-4'>
                //   <StyledTabs
                //     className='w-full'
                //     centered
                //     defaultActiveKey='1'
                //     items={[
                //       {
                //         key: '1',
                //         label: 'Essential',
                //         children: (
                //           <div className='flex flex-col gap-3'>
                //             <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Current Membership</div>

                //             <MembershipCard
                //               image={Front2}
                //               name='Essential Zelene Head Spa'
                //               description='Enjoy savings with us'
                //               price={89}
                //               date='1 month'
                //               showCheckbox={false}
                //             />

                //             <div className='flex flex-col gap-2 rounded-lg bg-white px-5 py-4'>
                //               <div className='flex gap-8 px-4 *:flex-1'>
                //                 <div className='font-hneu text-[12px] text-zelene-slight-blue'>Plan</div>
                //                 <div className='font-hneu text-[12px] text-zelene-slight-blue'>Expiration Date</div>
                //               </div>

                //               <div className='flex gap-8 bg-[#FBF5EA] px-4 py-3 *:flex-1'>
                //                 <div className='font-hneu text-[12px] font-medium text-zelene-blue'>
                //                   Essential Zelene Head Spa
                //                 </div>

                //                 <div className='font-hneu text-[12px] font-medium text-zelene-blue'>30 Days Left</div>
                //               </div>
                //             </div>

                //             <div className='flex gap-2 *:flex-1'>
                //               <Button
                //                 size='large'
                //                 className='!rounded-[44px] !border-zelene-red !bg-transparent !font-hneu !font-medium !text-zelene-red'
                //               >
                //                 Cancel Plan
                //               </Button>
                //               <Button
                //                 size='large'
                //                 className='!rounded-[44px] !border-zelene-yellow !bg-zelene-yellow !font-hneu !font-medium !text-white'
                //               >
                //                 Renewal
                //               </Button>
                //             </div>

                //             <Divider className='!my-6' />

                //             <div className='font-hneu text-[12px] font-medium text-zelene-blue'>
                //               Essential Plan Permission
                //             </div>

                //             <div className='flex flex-col gap-2 rounded-lg bg-white px-5 py-4'>
                //               <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Member Benefits</div>
                //               <div className='grid grid-cols-2 gap-[10px] pt-3 *:col-span-1 *:text-[12px] *:text-zelene-slight-blue'>
                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>Scalp Relaxation</span>
                //                 </div>

                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>Dry Out</span>
                //                 </div>
                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>Double Shampoo</span>
                //                 </div>
                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>Complimentary Tea</span>
                //                 </div>
                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>Conditioner</span>
                //                 </div>
                //               </div>
                //               <Divider className='!my-3' variant='dashed' />
                //               <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Member Pricing</div>
                //               <div className='grid grid-cols-2 gap-[10px] pt-3 *:col-span-1 *:text-[12px] *:text-zelene-slight-blue'>
                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>10% Service</span>
                //                 </div>
                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>5% Product</span>
                //                 </div>
                //               </div>
                //             </div>
                //           </div>
                //         ),
                //       },
                //       {
                //         key: '2',
                //         label: 'Premium',
                //         children: (
                //           <div className='flex flex-col gap-3'>
                //             <MembershipCard
                //               image={Front1}
                //               name='Premium Zelene Head Spa'
                //               description='Enjoy savings with us'
                //               price={149}
                //               date='1 month'
                //               showCheckbox={false}
                //             />

                //             <div className='flex gap-2 *:flex-1'>
                //               <Button
                //                 size='large'
                //                 className='!rounded-[44px] !border-zelene-yellow !bg-zelene-yellow !font-hneu !font-medium !text-white'
                //               >
                //                 Join Premium Plan Now
                //               </Button>
                //             </div>

                //             <Divider className='!my-6' />

                //             <div className='font-hneu text-[12px] font-medium text-zelene-blue'>
                //               Premium Plan Permission
                //             </div>

                //             <div className='flex flex-col gap-2 rounded-lg bg-white px-5 py-4'>
                //               <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Member Benefits</div>
                //               <div className='grid grid-cols-2 gap-[10px] pt-3 *:col-span-1 *:text-[12px] *:text-zelene-slight-blue'>
                //                 {[
                //                   'Scalp Relaxation',
                //                   'Neck & Shoulder Massage',
                //                   'Facial Massage',
                //                   'Double Shampoo',
                //                   'Hands & Arms Massage',
                //                   'Conditioner',
                //                   'Scalp Massage',
                //                   'Dry Out',
                //                   'Complimentary Tea',
                //                 ].map((item, index) => {
                //                   return (
                //                     <div className='flex items-center gap-2' key={index}>
                //                       <CheckCircleFilled className='!text-green-700' />
                //                       <span>{item}</span>
                //                     </div>
                //                   );
                //                 })}
                //               </div>
                //               <Divider className='!my-3' variant='dashed' />
                //               <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Member Pricing</div>
                //               <div className='grid grid-cols-2 gap-[10px] pt-3 *:col-span-1 *:text-[12px] *:text-zelene-slight-blue'>
                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>15% Service</span>
                //                 </div>
                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>10% Product</span>
                //                 </div>
                //               </div>
                //             </div>
                //           </div>
                //         ),
                //       },
                //       {
                //         key: '3',
                //         label: 'Elite',
                //         children: (
                //           <div className='flex flex-col gap-3'>
                //             <MembershipCard
                //               image={Front3}
                //               name='Elite Zelene Head Spa'
                //               description='Enjoy savings with us'
                //               price={199}
                //               date='1 month'
                //               showCheckbox={false}
                //             />

                //             <div className='flex gap-2 *:flex-1'>
                //               <Button
                //                 size='large'
                //                 className='!rounded-[44px] !border-zelene-yellow !bg-zelene-yellow !font-hneu !font-medium !text-white'
                //               >
                //                 Join Elite Plan Now
                //               </Button>
                //             </div>

                //             <Divider className='!my-6' />

                //             <div className='font-hneu text-[12px] font-medium text-zelene-blue'>
                //               Elite Plan Permission
                //             </div>

                //             <div className='flex flex-col gap-2 rounded-lg bg-white px-5 py-4'>
                //               <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Member Benefits</div>
                //               <div className='grid grid-cols-2 gap-[10px] pt-3 *:col-span-1 *:text-[12px] *:text-zelene-slight-blue'>
                //                 {[
                //                   '2 Add-ons Treatment OR 1 Pro-Algae Collagen Peel Facial Mask**',
                //                   'Scalp Relaxation',
                //                   'Neck & Shoulder Massage',
                //                   'Facial Massage',
                //                   'Hands & Arms Massage',
                //                   'Double Shampoo',
                //                   'Conditioner',
                //                   'Scalp Massage',
                //                   'Complimentary Tea',
                //                   'Dry Out',
                //                 ].map((item, index) => {
                //                   return (
                //                     <div className='flex items-center gap-2' key={index}>
                //                       <CheckCircleFilled className='!text-green-700' />
                //                       <span>{item}</span>
                //                     </div>
                //                   );
                //                 })}
                //               </div>
                //               <Divider className='!my-3' variant='dashed' />
                //               <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Member Pricing</div>
                //               <div className='grid grid-cols-2 gap-[10px] pt-3 *:col-span-1 *:text-[12px] *:text-zelene-slight-blue'>
                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>15% Service</span>
                //                 </div>
                //                 <div className='flex gap-2'>
                //                   <CheckCircleFilled className='!text-green-700' />
                //                   <span>10% Product</span>
                //                 </div>
                //               </div>
                //             </div>
                //           </div>
                //         ),
                //       },
                //     ]}
                //   />
                // </div>
              ),
            },
          ]}
          onChange={setActiveKey}
        />
      </div>
      <UpdatePassword
        isModalOpen={openUpdatePass}
        setOpenModal={setOpenUpdatePass}
        profile={profile}
        urlBack={urlBack}
      />
    </Modal>
  );
}
