import { LOCAL_LOCATION } from '@/constants';
import { CustomerMembershipDetail } from './membership-detail';
const storeBranches = LOCAL_LOCATION;
export const MembershipPersonal = () => {
  // const [storeBranches, setStoreBranches] = useState([]);
  // const [storeBranchesChoose, setStoreBranchesChoose] = useState<string | null>(null);

  // const fetchStoreBranches = useCallback(async () => {
  // const data = await StoreBranchApi.getAll();

  // setStoreBranches(data);
  // setStoreBranchesChoose(data[0]?._id);
  // }, []);

  // useEffect(() => {
  // fetchStoreBranches();
  // }, []);

  return (
    <div className='flex flex-col gap-2 rounded-lg bg-[#DBEAF8] p-4'>
      {/* <Select
        showSearch
        className='w-full'
        placeholder=''
        optionFilterProp='label'
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        value={storeBranchesChoose}
        options={storeBranches.map((v: any) => {
          return {
            label: v.name,
            value: v._id,
          };
        })}
        onChange={value => {
          setStoreBranchesChoose(value);
        }}
      /> */}
      {storeBranches ? <CustomerMembershipDetail storeBrandId={storeBranches} /> : <span>loading</span>}
    </div>
  );
};
